/* src/index.css (ou qualquer outro arquivo CSS global) */
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

/* src/Carousel.css */
.carousel-image {
    width: 100%;
    height: auto;
  }
  
  .slick-prev:before, .slick-next:before {
    color: black; /* Altere para a cor desejada */
  }
  
  .slick-prev {
    left: 10px; /* Ajuste conforme necessário */
    z-index: 1;
  }
  
  .slick-next {
    right: 10px; /* Ajuste conforme necessário */
    z-index: 1;
  }
  