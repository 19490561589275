/* src/App.css */
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
body {
  font-family: 400 14px Roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
  background-color: #f8f8f8;
}

header {
  background-color: #282c34;
  padding: 20px;
  color: white;
  text-align: center;
}
.oculto{
  display: none;
}
a{
  text-decoration: none;
  color: white;
}
nav ul {
  list-style: none;
  padding: 0;
}

nav ul li {
  display: inline;
  margin: 0 10px;
}

nav ul li a {
  color: white;
  text-decoration: none;
}

footer {
  background-color: #282c34;
  color: white;
  text-align: center;
  padding: 10px 0;
  bottom: 0;
  width: 100%;
}
/* src/Footer.css */

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

main {
}
