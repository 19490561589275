.produto{
  overflow: hidden;
  width: 160px;
  height: 250px;
  background: #fff;
    border-radius: 8px;
    box-shadow: 0 .0625rem .125rem 0 rgba(0, 0, 0, .1);
    color: rgba(0, 0, 0, .87);
    cursor: pointer;
    display: block;
    display: flex;
    flex-direction: column;
    padding: 0;
    position: relative;
    -webkit-text-decoration: none;
    text-decoration: none;
    transition: box-shadow .1s cubic-bezier(.4,0,.6,1), -webkit-transform .1s cubic-bezier(.4,0,.6,1);
    transition: transform .1s cubic-bezier(.4,0,.6,1), box-shadow .1s cubic-bezier(.4,0,.6,1);
    transition: transform .1s cubic-bezier(.4,0,.6,1), box-shadow .1s cubic-bezier(.4,0,.6,1), -webkit-transform .1s cubic-bezier(.4,0,.6,1);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.descricaoProduto{
  margin: 8px;
}
.precoProduto{
  margin-top: 8px;
  font-size: 24px;
  font-weight: 600;
}